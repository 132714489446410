import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import NewBill from "@modules/Payment/NewBill"
import { getExpirationBill } from "@utils/date"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"

const NewBillPage = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const { registrationSubscriber } = useStores()
  const { subscriberWirecardResponse } = registrationSubscriber

  const data = {
    day: getExpirationBill(),
    billetHref: _get(subscriberWirecardResponse, "_links.boleto.redirect_href"),
  }

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <div className="mc center">
        <NewBill data={data} />
      </div>
    </TranslateWrapper>
  )
}

export default NewBillPage
