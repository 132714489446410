import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Col, Row } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import React from "react"

import styles from "./style.module.less"

const NewBill = ({ data }) => {
  const { day, billetHref } = data

  return (
    <Row
      justify="center"
      className={cx("newBillContext mt72 ph16 ", styles.newBillContext)}
    >
      <Col
        span={24}
        md={12}
        lg={16}
        xl={12}
        className="flex-column justify-center items-center"
      >
        <Icon name="Bill" />

        <span className="fw5 f20 mine-shaft mb8 mt32">
          {translate("newBill")}
        </span>

        <span className="f14 scorpion-main mt8 tc mb24">
          {translate("newBill.description", {
            day: <span className="fw6">{translate("dayN", { day: day })}</span>,
          })}
        </span>

        <a
          href={billetHref}
          className="kimberly underline fw6"
          target="_blank"
          rel="noreferrer"
        >
          {translate("billetLink")}
        </a>

        <Link
          to="/"
          className={cx(
            "ant-btn ant-btn-primary mt24 ttu b items-center justify-center",
            styles.button
          )}
        >
          {translate("goToHome")}
        </Link>
      </Col>
    </Row>
  )
}

export default NewBill
